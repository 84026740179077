import { useEffect, useState } from "react"
import { Button, Divider, Modal, Space, Form, InputNumber, message } from "antd"
import { useNavigate } from "react-router-dom"
import { ButtonWithConfirm } from "../../components/delete"
import { EditMentorById } from "../../utils/apis"

const EditMentorModal = ({
    collectionName,
    mentor,
    open,
    handleCancel,
}: {
    collectionName: string
    mentor: Mentor | undefined
    open: boolean
    handleCancel: () => void
}) => {
    const navigate = useNavigate()

    const [price, setPrice] = useState<number>(mentor?.price ? mentor.price : 0)
    const [serviceFee, setServiceFee] = useState<number>(
        mentor?.serviceFee ? mentor.serviceFee : 0
    )

    useEffect(() => {
        setPrice(mentor?.price ? mentor.price : 0)
        setServiceFee(mentor?.serviceFee ? mentor.serviceFee : 0)
        console.debug("mentor changed", mentor?.price, mentor?.serviceFee)
    }, [mentor])

    return (
        <Modal
            width={800}
            open={open}
            title={`编辑${mentor?.name}的信息`}
            onCancel={() => {
                setPrice(mentor ? mentor.price : 0)
                setServiceFee(mentor ? mentor.serviceFee : 0)
                handleCancel()
            }}
            footer={[
                <Space key="footer" split={<Divider type="vertical" />}>
                    <ButtonWithConfirm
                        size="small"
                        type="primary"
                        handleAction={() => {
                            if (!mentor) return
                            console.debug(
                                collectionName,
                                mentor._id,
                                price,
                                serviceFee
                            )
                            EditMentorById({
                                collectionName,
                                id: mentor._id,
                                price,
                                serviceFee,
                            })
                                .then((res) => {
                                    console.debug(res)
                                    message.success({
                                        content: "更新成功",
                                        duration: 1,
                                        onClose: () => navigate(0),
                                    })
                                    navigate(0)
                                })
                                .catch((err) => {
                                    console.error(err)
                                    message.error("更新失败")
                                })
                        }}
                        confirmText={"正在更新"}
                        buttonText={"确定"}
                        title={`更新辅导价为${price}元，服务费为${serviceFee}元`}
                    />
                    <Button
                        size="small"
                        key="close"
                        onClick={() => {
                            setPrice(mentor ? mentor.price : 0)
                            setServiceFee(mentor ? mentor.serviceFee : 0)
                            handleCancel()
                        }}
                    >
                        关闭
                    </Button>
                </Space>,
            ]}
        >
            {mentor ? (
                <div style={{ maxHeight: "70vh", overflowY: "scroll" }}>
                    <Form
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 14 }}
                        layout="horizontal"
                        // style={{ maxWidth: 600 }}
                    >
                        <Form.Item label="辅导费（元）">
                            <InputNumber
                                defaultValue={mentor?.price}
                                value={price}
                                onChange={(v) => {
                                    if (v === null) return
                                    if (v >= 0) {
                                        setPrice(Math.floor(v))
                                    }
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="服务费（元）">
                            <InputNumber
                                defaultValue={mentor?.serviceFee}
                                value={serviceFee}
                                onChange={(v) => {
                                    if (v === null) return
                                    if (v >= 0) {
                                        setServiceFee(Math.floor(v))
                                    }
                                }}
                            />
                        </Form.Item>
                    </Form>
                </div>
            ) : (
                <></>
            )}
        </Modal>
    )
}

export { EditMentorModal }
