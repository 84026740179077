import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Button, Form, Input, Layout, Typography } from "antd"
import { Col, Row } from "antd"

import { auth, SignIn } from "../../utils/apis"

const { Footer } = Layout
const { Title } = Typography

const Login: React.FC = () => {
  const navigate = useNavigate()
  useEffect(() => {
    auth.getLoginState().then(async (loginState) => {
      if (loginState) {
        // 登录态有效
        console.debug("Logged in, Welcome!")
        navigate("/mentors-study")
      }
    })
  }, [])

  const onFinish = (values: any) => {
    console.debug("Success: form values are all valid")
    const { email, password } = values
    SignIn(email, password)
      .then((loginState) => {
        console.debug("login success")
        navigate("/mentors-study")
      })
      .catch((err) => console.error(err))
  }

  const onFinishFailed = (errorInfo: any) => {
    console.debug("Failed:", errorInfo)
  }

  useEffect(() => {
    localStorage.removeItem("query")
    localStorage.removeItem("page")
  }, [])

  return (
    <Layout>
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Col style={{ width: "fit-content" }}>
          <Title style={{ textAlign: "center" }}>依蕴管理端</Title>
          <Row justify="center" align="middle" style={{ margin: 100 }}>
            <Form name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} initialValues={{}} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
              <Form.Item
                label="邮箱"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "请输入正确的邮箱地址",
                  },
                ]}
              >
                <Input autoComplete="on" />
              </Form.Item>

              <Form.Item
                label="密码"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "请输入正确的密码",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  登录
                </Button>
              </Form.Item>
            </Form>
          </Row>
          <Footer style={{ textAlign: "center" }}>依蕴 @2023</Footer>
        </Col>
      </Row>
    </Layout>
  )
}

export { Login }
