import { FC, ReactNode, useEffect } from "react"
import { Layout, Menu } from "antd"
import { useNavigate } from "react-router-dom"

import type { MenuProps } from "antd"

import { auth } from "../../utils/apis"

const { Header, Footer } = Layout

const navItems: MenuProps["items"] = [
  { label: "求学导师", name: "mentors-study" },
  { label: "求职导师", name: "mentors-work" },
  { label: "求学辅导订单", name: "orders-study" },
  { label: "求职辅导订单", name: "orders-work" },
].map((item) => ({
  key: item.name,
  title: item.name,
  label: item.label,
}))

const Page: FC<{ name: string; children: ReactNode }> = ({ name, children }) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (auth.hasLoginState()) {
      // 登录态有效
      console.debug("Logged in, Welcome!")
    } else {
      // 没有登录态，或者登录态已经失效
      console.debug("Have not logged in yet")
      navigate("/")
    }
  }, [])

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header style={{ display: "flex", backgroundColor: "#fff", position: "sticky", top: "0px", zIndex: "99" }}>
        <h2 style={{ margin: "auto 50px auto 0px" }}>依蕴4U</h2>
        <Menu theme="light" mode="horizontal" selectedKeys={[name]} items={navItems} onClick={(e) => navigate(`/${e.key.toLowerCase()}`)} />
      </Header>
      {children}
      <Footer style={{ textAlign: "center" }}>依蕴4U @2023</Footer>
    </Layout>
  )
}

export { Page }
