import { FC } from "react"
import { Button, message, Popconfirm, Typography } from "antd"
import { SizeType } from "antd/es/config-provider/SizeContext"

const { Text } = Typography

const Delete: FC<{ handleDelete: () => void }> = ({ handleDelete }) => {
  const confirm = () => {
    message.success("正在删除...")
    handleDelete()
  }

  return (
    <Popconfirm title="你确定要删除这条数据吗?" onConfirm={confirm} okText="是" cancelText="否">
      <Text style={{ color: "red" }}>删除</Text>
    </Popconfirm>
  )
}

const ButtonWithConfirm: FC<{
  handleAction: () => void
  size?: SizeType
  danger?: boolean
  type?: "link" | "text" | "ghost" | "default" | "primary" | "dashed" | undefined
  disabled?: boolean
  buttonText: string
  title: string
  confirmText: string
}> = ({ handleAction, disabled = false, size = "small", danger = false, type = undefined, buttonText, title, confirmText }) => {
  const confirm = () => {
    message.loading(confirmText)
    handleAction()
  }

  return (
    <Popconfirm title={title} disabled={disabled} onConfirm={confirm} okText="是" cancelText="否">
      <Button danger={danger} disabled={disabled} type={type} size={size}>
        {buttonText}
      </Button>
    </Popconfirm>
  )
}

export { Delete, ButtonWithConfirm }
