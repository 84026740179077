import { Button, Divider, Modal, Space, Tag } from "antd"
import { timeSlot } from "../../utils/timeSlot"
import { ButtonWithConfirm } from "../../components/delete"

const MentorModal = ({
    mentor,
    open,
    handleCancel,
    handleOnline,
    handleOffline,
    handleDelete,
}: {
    mentor: Mentor | undefined
    open: boolean
    handleCancel: () => void
    handleOnline?: (id: string) => void
    handleOffline?: (id: string) => void
    handleDelete?: (id: string) => void
}) => {
    return (
        <Modal
            width={800}
            open={open}
            title={`${mentor?.name}的详细信息`}
            onCancel={handleCancel}
            footer={[
                <Space key="footer" split={<Divider type="vertical" />}>
                    <Button size="small" key="close" onClick={handleCancel}>
                        关闭
                    </Button>
                    {handleOnline ? (
                        <ButtonWithConfirm
                            size="small"
                            type="primary"
                            disabled={mentor?.reviewed}
                            key="online"
                            handleAction={() =>
                                mentor ? handleOnline(mentor._id) : {}
                            }
                            confirmText={"开始执行“上线”操作"}
                            buttonText={"上线"}
                            title={
                                "你确定要将这条记录下线吗？下线后这条记录将对所有用户不可见"
                            }
                        />
                    ) : (
                        <></>
                    )}
                    {handleOffline ? (
                        <ButtonWithConfirm
                            size="small"
                            type="primary"
                            disabled={!mentor?.reviewed}
                            key="offline"
                            handleAction={() =>
                                mentor ? handleOffline(mentor._id) : {}
                            }
                            confirmText={"开始执行“下线”操作"}
                            buttonText={"下线"}
                            title={
                                "你确定要将这条记录上线吗？上线后这条记录将对所有用户可见"
                            }
                        />
                    ) : (
                        <></>
                    )}
                    {handleDelete ? (
                        <ButtonWithConfirm
                            size="small"
                            danger
                            type="primary"
                            key="delete"
                            handleAction={() =>
                                mentor ? handleDelete(mentor._id) : {}
                            }
                            confirmText={"开始执行“删除”操作"}
                            buttonText={"删除"}
                            title={"你确定要删除这条记录吗？删除操作不可恢复"}
                        />
                    ) : (
                        <></>
                    )}
                </Space>,
            ]}
        >
            {mentor ? (
                <div style={{ maxHeight: "70vh", overflowY: "scroll" }}>
                    <h3>基础信息</h3>
                    <p>姓名：{mentor.name}</p>
                    <p>微信：{mentor.wechat}</p>
                    <p>分类：{mentor.category}</p>
                    <p>收费（元/小时）：{mentor.price}</p>

                    <h3>教育信息</h3>
                    <p>获得过 offer 的大学:</p>
                    {mentor.offers.map((offer) => {
                        return <Tag key={`detail-${offer}`}>{offer}</Tag>
                    })}

                    <h4>高中</h4>
                    <p>高中学校名称: {mentor.highSchool.name}</p>
                    <p>高中学校体系: {mentor.highSchool.system}</p>

                    <h4>本科</h4>
                    <p>本科大学名称: {mentor.universities.bachelor.name}</p>
                    <p>本科大学专业: {mentor.universities.bachelor.course}</p>
                    <p>
                        本科大学在读状态:{" "}
                        {mentor.universities.bachelor.finished
                            ? "已毕业"
                            : "在读"}
                    </p>

                    {mentor.universities.master ? (
                        <>
                            <h4>硕士</h4>
                            <p>
                                硕士大学名称: {mentor.universities.master.name}
                            </p>
                            <p>
                                硕士大学专业:{" "}
                                {mentor.universities.master.course}
                            </p>
                            <p>
                                硕士大学在读状态:{" "}
                                {mentor.universities.master.finished
                                    ? "已毕业"
                                    : "在读"}
                            </p>
                        </>
                    ) : (
                        <></>
                    )}

                    <h3>自我介绍</h3>
                    <p>{mentor.introduction}</p>

                    <h3>你觉得能吸引到学生的成就</h3>
                    <p>{mentor.achievement}</p>

                    <h3>为什么想来</h3>
                    <p>{mentor.reason}</p>

                    <h3>可以辅导的时间段</h3>
                    {mentor.slots.map((t) => {
                        return <Tag key={`weekend-${t}`}>{timeSlot(t)}</Tag>
                    })}
                </div>
            ) : (
                <></>
            )}
        </Modal>
    )
}

export { MentorModal }
