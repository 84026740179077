import { FC } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { Login } from "./pages/login"
import { Mentors } from "./pages/mentors"
import { Orders } from "./pages/orders"
import { ORDERS_STUDY_DB, ORDERS_WORK_DB } from "./utils/env"
import "./App.css"

const App: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/mentors-work" element={<Mentors collectionName={"work"} name={"mentors-work"} />} />
        <Route path="/mentors-study" element={<Mentors collectionName={"study"} name={"mentors-study"} />} />
        <Route path="/orders-work" element={<Orders collectionName={"work"} name={"orders-work"} />} />
        <Route path="/orders-study" element={<Orders collectionName={"study"} name={"orders-study"} />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
