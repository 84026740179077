import cloudbase from "@cloudbase/js-sdk"
import { ENV_ID } from "./env"

const app = cloudbase.init({ env: ENV_ID })

const auth = app.auth({ persistence: "local" })

const SignIn = (email: string, password: string) => {
    return auth.signInWithEmailAndPassword(email, password)
}

const SignUp = (email: string, password: string) => {
    auth.signUpWithEmailAndPassword(email, password)
        .then((res) => {
            console.log(res)
        })
        .catch((err) => {
            console.log(err)
        })
}

const GetLoginState = async (email: string, password: string) => {
    const loginState = await auth.getLoginState()
    if (loginState) {
        // 登录态有效
        return loginState
    }
    return await auth.signInWithEmailAndPassword(email, password)
}

const DeleteMentor = ({
    mentorId,
    collectionName,
}: {
    mentorId: string
    collectionName: string
}) => {
    return app.callFunction({
        name: "deleteMentor",
        data: {
            collectionName: collectionName,
            mentorId: mentorId,
        },
    })
}

const GetMentors = ({
    collectionName,
    page,
    pageSize,
    reviewed,
}: {
    collectionName: string
    page: number
    pageSize: number
    reviewed: boolean
}) => {
    return app.callFunction({
        name: "getMentors",
        data: {
            collectionName: collectionName,
            page: page,
            pageSize: pageSize,
            reviewed: reviewed,
        },
    })
}

const GetMentorById = ({
    collectionName,
    id,
}: {
    collectionName: string
    id: string
}) => {
    return app.callFunction({
        name: "getMentorById",
        data: {
            collectionName: collectionName,
            id: id,
        },
    })
}

const EditMentorById = ({
    collectionName,
    id,
    price,
    serviceFee,
}: {
    collectionName: string
    id: string
    price: number
    serviceFee: number
}) => {
    return app.callFunction({
        name: "editMentorById",
        data: {
            collectionName: collectionName,
            id: id,
            price: price,
            serviceFee: serviceFee,
        },
    })
}

const UpdateMentorReviewStatus = ({
    collectionName,
    id,
    reviewed,
}: {
    collectionName: string
    id: string
    reviewed: boolean
}) => {
    return app.callFunction({
        name: "updateMentorReviewStatus",
        data: {
            collectionName: collectionName,
            id: id,
            reviewed: reviewed,
        },
    })
}

const GetMentorsCount = ({
    collectionName,
    reviewed,
}: {
    collectionName: string
    reviewed: boolean
}) => {
    return app.callFunction({
        name: "getMentorsCount",
        data: {
            collectionName: collectionName,
            reviewed: reviewed,
        },
    })
}

const GetOrders = ({
    type,
    paid,
    page = 1,
    pageSize = 10,
}: {
    type: string
    paid: boolean | undefined
    page: number
    pageSize: number
}) => {
    return app.callFunction({
        name: "getOrders",
        data: {
            type: type,
            paid: paid,
            page: page,
            pageSize: pageSize,
        },
    })
}

const GetOrdersCount = ({
    type,
    paid,
}: {
    type: string
    paid: boolean | undefined
}) => {
    return app.callFunction({
        name: "getOrdersCount",
        data: {
            type: type,
            paid: paid,
        },
    })
}

const DownloadFile = (fileID: string) => {
    return app
        .downloadFile({
            fileID: fileID,
        })
        .then((res) => console.debug(res))
}

export {
    auth,
    GetLoginState,
    SignUp,
    SignIn,
    DeleteMentor,
    GetMentors,
    GetMentorsCount,
    GetMentorById,
    EditMentorById,
    GetOrders,
    GetOrdersCount,
    UpdateMentorReviewStatus,
    DownloadFile,
}
