import { FC, useState, useEffect } from "react"
import { Layout, Table, Button, message } from "antd"
import { Page } from "../../components/template"
import {
    DownloadFile,
    GetMentorById,
    GetOrders,
    GetOrdersCount,
} from "../../utils/apis"
import { timeSlot } from "../../utils/timeSlot"
// import { mock_orders } from "./data"
import { MentorModal } from "../../components/mentorModal"

import type { ColumnsType } from "antd/es/table"

import "./index.css"

const columns = (
    collectionName: string,
    openModal: () => void,
    setMentor: (mentor: Mentor) => void
): ColumnsType<Order> => [
    {
        title: "订单编号",
        dataIndex: "_id",
        width: 300,
        // ellipsis: true,
        fixed: "left",
    },
    {
        title: "订单详情",
        // width: 600,
        children: [
            {
                title: "导师编号",
                dataIndex: "mentorId",
                width: 350,
                // ellipsis: true,
                render(value, order, index) {
                    return (
                        <Button
                            block
                            style={{ height: "auto" }}
                            type="link"
                            size="small"
                            onClick={() => {
                                console.debug("Get mentor by id: ", value)
                                message.loading("正在获取导师信息...")
                                GetMentorById({
                                    collectionName: collectionName,
                                    id: value,
                                })
                                    .then((res) => {
                                        console.debug(res.result)
                                        if (res.result.error) {
                                            message.error(
                                                `获取导师信息失败: ${res.result.error.errMsg}`
                                            )
                                            return
                                        }
                                        setMentor(res.result.result.data)
                                        openModal()
                                    })
                                    .catch((err) => {
                                        console.error(err)
                                    })
                            }}
                        >
                            {value}
                        </Button>
                    )
                },
            },
            {
                title: "导师姓名",
                dataIndex: "mentorName",
                width: 100,
            },
            {
                title: "辅导类型",
                dataIndex: "type",
                width: 80,
                render(value, order, index) {
                    return (
                        <span>
                            {value === "study"
                                ? "求学"
                                : value === "work"
                                ? "求职"
                                : "出错"}
                        </span>
                    )
                },
            },
            {
                title: "辅导科目",
                dataIndex: "category",
                width: 100,
            },
            {
                title: "辅导日期（北京时间）",
                dataIndex: "date",
                width: 160,
            },
            {
                title: "辅导时间段（北京时间）",
                dataIndex: "time",
                width: 180,
            },
        ],
    },
    {
        title: "交易信息",
        children: [
            {
                title: "交易编号",
                dataIndex: "transactionId",
                width: 260,
                // ellipsis: true,
            },
            {
                title: "订单创建时间",
                dataIndex: "createdAt",
                // width: 200,
                // ellipsis: true,
            },
            {
                title: "总收费（元）",
                dataIndex: "fee",
                align: "right",
                width: 110,
            },
            {
                title: "服务费（元）",
                dataIndex: "serviceFee",
                align: "right",
                width: 110,
            },
            {
                title: "实际收费（元）",
                dataIndex: "feeActual",
                align: "right",
                width: 120,
                render(value, order, index) {
                    return <span>{(value / 100).toFixed(2)}</span>
                },
            },
            {
                title: "收费状态",
                dataIndex: "paid",
                width: 100,
                render(value, order, index) {
                    return <span>{value ? "已付款" : "未付款"}</span>
                },
            },
        ],
    },
    {
        title: "学生信息",
        // fixed: "right",
        width: 800,
        children: [
            {
                title: "openid",
                dataIndex: "openid",
                width: 270,
            },
            {
                title: "微信",
                dataIndex: "studentWechat",
                width: 120,
            },
            {
                title: "自我介绍",
                dataIndex: "introduction",
                width: 300,
                ellipsis: true,
            },
            {
                title: "最新简历",
                dataIndex: "resume",
                width: 80,
                // ellipsis: true,
                render(value, order, index) {
                    return (
                        <Button
                            block
                            type="link"
                            onClick={() => {
                                DownloadFile(value)
                            }}
                        >
                            下载
                        </Button>
                    )
                },
            },
        ],
    },
]

const { Content } = Layout

const Orders: FC<{ name: string; collectionName: string }> = ({
    name,
    collectionName,
}) => {
    const [loading, setLoading] = useState(false)
    const [orders, setOrders] = useState<RawOrder[]>([])
    const [mentor, setMentor] = useState<Mentor>()
    const [open, setOpen] = useState<boolean>(false)
    const [paid, setPaid] = useState<boolean | undefined>(undefined)
    // const [type, setType] = useState<string>()
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)
    const [ordersCount, setOrdersCount] = useState<number>(0)

    const handleOk = () => {
        setOpen(false)
    }

    const handleCancel = () => {
        setOpen(false)
    }

    const openModal = () => {
        setOpen(true)
    }

    useEffect(() => {
        setLoading(true)
        const promise1 = GetOrders({
            type: collectionName,
            paid: paid,
            page: page,
            pageSize: pageSize,
        })
        const promise2 = GetOrdersCount({
            type: collectionName,
            paid: paid,
        })
        Promise.all([promise1, promise2])
            .then(([res1, res2]) => {
                console.debug(res1.result, res2.result)
                setOrders(res1.result.result.data)
                setOrdersCount(res2.result.result.total)
                setLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setLoading(false)
            })
        // setLoading(false)
    }, [collectionName, page, pageSize, paid])

    return (
        <Page name={name}>
            <Content
                style={{
                    backgroundColor: "#fff",
                    margin: "auto",
                    padding: "20px 10px",
                    minHeight: 280,
                    width: "100%",
                }}
            >
                <Table
                    size="small"
                    scroll={{ x: 3000 }}
                    bordered
                    columns={columns(collectionName, openModal, setMentor)}
                    dataSource={orders.map((order) => {
                        return {
                            ...order,
                            key: order._id,
                            time: timeSlot(order.time),
                            createdAt: order.createdAt
                                ? new Date(order.createdAt).toLocaleString(
                                      "zh-cn",
                                      {
                                          dateStyle: "full",
                                          timeStyle: "full",
                                          timeZone: "Asia/Shanghai",
                                      }
                                  )
                                : order.createdAt,
                        }
                    })}
                    loading={loading}
                    pagination={{
                        position: ["bottomCenter"],
                        total: ordersCount,
                        current: page,
                        onChange: (page) => setPage(page),
                    }}
                />
            </Content>
            <MentorModal
                mentor={mentor}
                open={open}
                handleCancel={handleCancel}
            />
        </Page>
    )
}

export { Orders }
