import { FC, useState, useEffect } from "react"
import {
    Avatar,
    Button,
    Divider,
    Layout,
    List,
    Space,
    Tag,
    Segmented,
    message,
} from "antd"
import { Page } from "../../components/template"
import {
    GetMentors,
    GetMentorsCount,
    UpdateMentorReviewStatus,
    DeleteMentor,
} from "../../utils/apis"
import { useNavigate } from "react-router-dom"
import { timeSlot } from "../../utils/timeSlot"
import { ButtonWithConfirm } from "../../components/delete"

import "./index.css"
import { MentorModal } from "../../components/mentorModal"
import { EditMentorModal } from "../../components/editMentorModal"

const { Content } = Layout

const Mentors: FC<{ name: string; collectionName: string }> = ({
    name,
    collectionName,
}) => {
    const [loading, setLoading] = useState(false)
    const [mentors, setMentors] = useState<Mentor[]>([])
    const [mentor, setMentor] = useState<Mentor | undefined>(undefined)
    const [open, setOpen] = useState<boolean>(false)
    const [editOpen, setEditOpen] = useState<boolean>(false)
    const [page, setPage] = useState<number>(1)
    const [reviewed, setReviewed] = useState<boolean>(true)
    const [pageSize, setPageSize] = useState<number>(10)
    const [mentorsCount, setMentorsCount] = useState<number>(100)

    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        const promise1 = GetMentors({
            collectionName: collectionName,
            reviewed: reviewed,
            page: page,
            pageSize: pageSize,
        })
        const promise2 = GetMentorsCount({
            collectionName: collectionName,
            reviewed: reviewed,
        })
        Promise.all([promise1, promise2])
            .then(([res1, res2]) => {
                console.debug(res1.result, res2.result)
                setMentors(res1.result.result.data)
                setMentorsCount(res2.result.result.total)
                setLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setLoading(false)
            })
        // setMentors(mock_mentors(name === "mentors-work" ? "work" : "study"))
        // setLoading(false)
    }, [collectionName, page, pageSize, reviewed])

    useEffect(() => {
        setPage(1)
    }, [collectionName])

    const handleCancel = () => {
        setOpen(false)
    }
    const handleEditCancel = () => {
        setEditOpen(false)
    }
    const handleOnline = (id: string) => {
        UpdateMentorReviewStatus({
            collectionName: collectionName,
            id: id,
            reviewed: true,
        })
            .then((res) => {
                console.debug(res)
                message.success({
                    content: "上线成功",
                    duration: 1,
                    onClose: () => navigate(0),
                })
                navigate(0)
            })
            .catch((err) => {
                console.error(err)
                message.error("上线失败")
            })
    }
    const handleOffline = (id: string) => {
        UpdateMentorReviewStatus({
            collectionName: collectionName,
            id: id,
            reviewed: false,
        })
            .then((res) => {
                console.debug(res)
                message.success({
                    content: "下线成功",
                    duration: 1,
                    onClose: () => navigate(0),
                })
            })
            .catch((err) => {
                console.error(err)
                message.error("下线失败")
            })
    }

    const handleDelete = (id: string) => {
        DeleteMentor({ collectionName: collectionName, mentorId: id })
            .then((res) => {
                console.debug(res)
                message.success({
                    content: "删除成功",
                    duration: 1,
                    onClose: () => navigate(0),
                })
            })
            .catch((err) => {
                console.error(err)
                message.error("删除失败")
            })
    }

    return (
        <Page name={name}>
            <Content
                style={{
                    backgroundColor: "#fff",
                    margin: "auto",
                    padding: "20px 10px",
                    minHeight: 280,
                }}
            >
                <Space
                    direction="vertical"
                    style={{ display: "flex", width: "21cm", margin: "auto" }}
                >
                    <Segmented
                        block
                        size="middle"
                        options={["已上线", "未上线"]}
                        onChange={(val) => {
                            setPage(1)
                            switch (val) {
                                case "已上线":
                                    setReviewed(true)
                                    break
                                case "未上线":
                                    setReviewed(false)
                                    break
                                default:
                                    break
                            }
                        }}
                    />
                    <List
                        loading={loading}
                        itemLayout="vertical"
                        dataSource={mentors}
                        rowKey={(mentor) => mentor._id}
                        renderItem={(mentor) => (
                            <List.Item
                                actions={[
                                    <Space split={<Divider type="vertical" />}>
                                        <Button
                                            key="edit"
                                            size="small"
                                            onClick={() => {
                                                setMentor(mentor)
                                                setEditOpen(true)
                                            }}
                                        >
                                            编辑
                                        </Button>
                                        <ButtonWithConfirm
                                            key="delete"
                                            type={"primary"}
                                            danger
                                            handleAction={() =>
                                                handleDelete(mentor._id)
                                            }
                                            confirmText={"正在执行“删除”操作"}
                                            buttonText={"删除"}
                                            title={
                                                "你确定要删除这条记录吗？删除操作不可恢复"
                                            }
                                        />
                                        <ButtonWithConfirm
                                            type={"primary"}
                                            disabled={!mentor.reviewed}
                                            key="offline"
                                            handleAction={() =>
                                                handleOffline(mentor._id)
                                            }
                                            confirmText={"正在执行“下线”操作"}
                                            buttonText={"下线"}
                                            title={
                                                "你确定要将这条记录下线吗？下线后这条记录将对所有用户不可见"
                                            }
                                        />
                                        <ButtonWithConfirm
                                            type={"primary"}
                                            disabled={mentor.reviewed}
                                            key="online"
                                            handleAction={() =>
                                                handleOnline(mentor._id)
                                            }
                                            confirmText={"正在执行“上线”操作"}
                                            buttonText={"上线"}
                                            title={
                                                "你确定要将这条记录上线吗？上线后这条记录将对所有用户可见"
                                            }
                                        />
                                        <Button
                                            key="more"
                                            size="small"
                                            onClick={() => {
                                                setMentor(mentor)
                                                setOpen(true)
                                            }}
                                        >
                                            详情
                                        </Button>
                                    </Space>,
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={<Avatar src={mentor.avatar} />}
                                    title={
                                        <Space>
                                            {mentor.name}
                                            <Tag color="green">
                                                {mentor.category}
                                            </Tag>
                                        </Space>
                                    }
                                    description={
                                        mentor.introduction.length > 90
                                            ? mentor.introduction.slice(0, 90) +
                                              "..."
                                            : mentor.introduction
                                    }
                                />
                                <div style={{ marginLeft: "48px" }}>
                                    <h5 style={{ marginBottom: "5px" }}>
                                        获得过 offer 的大学:
                                    </h5>
                                    {mentor.offers.map((offer) => {
                                        return <Tag key={offer}>{offer}</Tag>
                                    })}
                                    <h5 style={{ marginBottom: "5px" }}>
                                        辅导费（元 / 小时）:
                                    </h5>
                                    <div>{mentor.price}</div>
                                    <h5 style={{ marginBottom: "5px" }}>
                                        服务费（元 / 小时）:
                                    </h5>
                                    <div>{mentor.serviceFee}</div>
                                </div>
                            </List.Item>
                        )}
                        pagination={{
                            onChange: (page) => setPage(page),
                            current: page,
                            total: mentorsCount,
                            onShowSizeChange: (current, size) => {
                                setPageSize(size)
                            },
                        }}
                    />
                </Space>
            </Content>
            <MentorModal
                mentor={mentor}
                open={open}
                handleCancel={handleCancel}
                handleOnline={handleOnline}
                handleOffline={handleOffline}
                handleDelete={handleDelete}
            />
            <EditMentorModal
                collectionName={collectionName}
                mentor={mentor}
                open={editOpen}
                handleCancel={handleEditCancel}
            />
        </Page>
    )
}

export { Mentors }
